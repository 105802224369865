<template>
    <versus />
</template>
<script>
import versus from '@/components/student/versus/index.vue'

export default {
  name: 'viewVersus',
  components: {
    versus
  },
}
</script>
